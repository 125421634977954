//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { tasksStore, uiStore } from '@/store'

export default {
  name: 'ImportTasks',
  components: {
    'step-1': () => import('./Step1'),
    'step-2': () => import('./Step2'),
    'step-3': () => import('./Step3'),
  },
  data () {
    return {
      step: 1,
      parsedTasks: [],
      importStarting: false,
      importError: null,
      currentImportUID: null,
    }
  },
  computed: {
    noTasksFound () {
      return this.parsedTasks.length <= 0
    },
    step2color () {
      return this.step === 2 && this.noTasksFound
        ? 'negative'
        : 'main-accent'
    },
    nextButton () {
      const dataLength = this.parsedTasks.filter(item => item.import).length

      return {
        enabled: dataLength > 0,
        action: this.importTasks,
        loading: this.importStarting,
        label: this.$t('modals.ImportTasks.step2.btnLabel'),
        badge: dataLength,
      }
    },
  },
  methods: {
    async importTasks () {
      this.importStarting = true
      this.importError = null
      const tasks = this.parsedTasks.filter(task => task.import)
      try {
        this.currentImportUID = await tasksStore.actions.import(tasks)
        this.$refs.stepper.next()
      } catch (e) {
        this.importError = e?.details
          ? Object.values(e.details).join(' ')
          : e.error
      }
      this.importStarting = false
    },
    close () {
      uiStore.actions.hideModal()
    },
  },
}
